"use client";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useRouter, createSearchParams } from "next/navigation";
import Image from "next/image";

import quickLinksJSONData from "../../json/quick-links/quick-links-names.json";

// import { createSearchParams } from "react-router-dom";

import menuItemBg from "../../assets/backgrounds/menu-item-bg.webp";
import logo from "../../assets/logo/duby-drive.png";

import citiesIcon from "../../assets/icons/cities.png";
import brandIcon from "../../assets/icons/brand.png";
import carIcon from "../../assets/icons/sports-car.png";
import yachtIcon from "../../assets/icons/boat.png";
import driverIcon from "../../assets/icons/driver.png";
import camelIcon from "../../assets/icons/camel.png";
import bikeIcon from "../../assets/icons/bike.png";
import aboutIcon from "../../assets/icons/about.png";
import contactIcon from "../../assets/icons/contact-us.png";
import quickLinksIcon from "../../assets/icons/quick-links.png";

import hamBurger from "../../assets/icons/hamburger.png";

import navLocationsIcon from "../../assets/icons/nav-locations.png";
import navBrandIcon from "../../assets/icons/nav-brands.png";
import navCarIcon from "../../assets/icons/nav-cars.png";
import navYachtIcon from "../../assets/icons/nav-yacht.png";
import navCamelIcon from "../../assets/icons/nav-camel.png";
import navBikingIcon from "../../assets/icons/nav-biking.png";
import navLinksIcon from "../../assets/icons/nav-links.png";

import earthIcon from "../../assets/icons/planet-earth.png";
import companyIcon from "../../assets/icons/company.png";

import fbIcon from "../../assets/icons/facebook.png";
import instaIcon from "../../assets/icons/instagram.png";
import tiktokIcon from "../../assets/icons/tiktok.png";
import youtubeIcon from "../../assets/icons/youtube.png";
import Link from "next/link";

const Navbar = () => {
  const router = useRouter();

  const createSearchParams = (params) => {
    return new URLSearchParams(params).toString();
  };

  // Function to handle navigation
  const handleNavigation = (router, path, query) => {
    const searchParams = createSearchParams(query);
    const url = `${path}?${searchParams}`;
    router.push(url);
  };

  const handleNavigationyachts = () => {
    router.push("/yachts");
  };

  const handleClickquick = (item) => {
    const pathname = "/cars";
    const query = `quick_link=${encodeURIComponent(item.toLowerCase())}`;

    // Navigate to the new route with query parameters
    router.push(`${pathname}?${query}`);
  };

  const handleClick = (item) => {
    console.log(item,'otem')
    const pathname = "/cars";
    const query = `brand=${item.brandName.toLowerCase()}`;
    // Navigate to the new route with query parameters
    router.push(`${pathname}?${query}`, undefined, { shallow: true });
  };

  const [quickLinksData, setQuickLinksData] = useState(quickLinksJSONData);

  const [megaMenu, setMegaMenu] = useState(false);
  const [rentACarMenu, setRentACarMenu] = useState(false);
  const [hotDeals, setHotDeals] = useState(false);

  const [brandsData, setBrandsData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [carTypeData, setCarTypeData] = useState([]);
  const [driverServiceData, setDriverServiceData] = useState([]);

  const [lengthData, setLengthData] = useState([]);

  const fetchingBrandsApi = async () => {
    try {
      let response = await axios({
        method: "GET",
        url: `${process.env.NEXT_PUBLIC_REACT_APP_HOST_URL}/api/cars-brand`,
      });
      // console.log(response);
      if (response.status == 200) {
        setBrandsData(response.data);
      }
    } catch (error) {
      console.log("Failed while fetching brands api: ", error);
    }
  };

  const fetchingCitiesApi = async () => {
    try {
      let response = await axios({
        method: "GET",
        url: `${process.env.NEXT_PUBLIC_REACT_APP_HOST_URL}/api/cities`,
      });
      // console.log(response);
      if (response.status == 200) {
        setCitiesData(response.data);
      }
    } catch (error) {
      console.log("Failed while fetching cities data api: ", error);
    }
  };

  const fetchingCarTypeDataApi = async () => {
    try {
      let response = await axios({
        method: "GET",
        url: `${process.env.NEXT_PUBLIC_REACT_APP_HOST_URL}/api/cars`,
      });
      // console.log(response);
      if (response.status == 200) {
        setCarTypeData(response.data);
      }
    } catch (error) {
      console.log("Failed while fetching car type data api: ", error);
    }
  };

  const fetchingCarsCountApiByBrand = async () => {
    try {
      let response = await axios({
        method: "GET",
        url: `${process.env.NEXT_PUBLIC_REACT_APP_HOST_URL}/api/cars-brand`,
      });
      // console.log(response);
      if (response.status == 200) {
        setLengthData(response.data);
      }
    } catch (error) {
      console.log("Failed while fetching car brands length api: ", error);
    }
  };

  const fetchingDriverServiceApi = async () => {
    try {
      let response = await axios({
        method: "GET",
        url: `${process.env.NEXT_PUBLIC_REACT_APP_HOST_URL}/api/get/data/safeDrivers/by/status`,
      });
      // console.log('drivers data', response);
      if (response.status == 200) {
        setDriverServiceData(response.data.data);
      }
    } catch (error) {
      console.log("Failed while fetching driver service data api: ", error);
    }
  };

  useEffect(() => {
    fetchingBrandsApi();
    fetchingCitiesApi();
    fetchingCarTypeDataApi();
    fetchingCarsCountApiByBrand();
    fetchingDriverServiceApi();
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-mobile">
        <div className="container-fluid container-fluid-navbar">
          <div
            className="collapse navbar-collapse ul-parent-container"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mb-2 mb-lg-0 ul-list-container">
              <li
                className="nav-item links"
                onMouseOver={() => {
                  setRentACarMenu(true);
                }}
                onMouseLeave={() => {
                  setRentACarMenu(false);
                }}
                onClick={() => {
                  setRentACarMenu(true);
                }}
              >
                <a
                  className="navbar_links links_hover nav-link"
                  aria-current="page"
                >
                  <Image
                    src={citiesIcon}
                    alt="cities-icon"
                    height={20}
                    width={20}
                    className="nav-icons"
                  />
                  Locations
                </a>
              </li>
              <li
                className="nav-item links"
                onMouseOver={() => {
                  setMegaMenu(true);
                }}
                onMouseLeave={() => {
                  setMegaMenu(false);
                }}
                onClick={() => {
                  router.push("brands");
                }}
              >
                <a className="navbar_links links_hover nav-link">
                  <Image
                    src={brandIcon}
                    alt="brand-icon"
                    height={23}
                    width={23}
                    className="nav-icons"
                  />
                  Car Brands
                </a>
              </li>
              <li
                onMouseOver={() => {
                  document.querySelector(".dropdown-menu-rental").classList.add("show");
                }}
                onMouseLeave={() => {
                  document.querySelector(".dropdown-menu-rental").classList.remove("show");
                }}
                className="nav-item links dropdown"
              >
                <a
                  className="navbar_links links_hover nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <Image
                    src={carIcon}
                    alt="car-icon"
                    height={20}
                    width={20}
                    className="nav-icons"
                  />
                  Rental Cars
                </a>
                <ul className="dropdown-menu dropdown-menu-rental">
                  <li onClick={() => router.push("cars")}>
                    <a className="dropdown-item">Rental Cars</a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li onClick={() => router.push("packages")}>
                    <a className="dropdown-item">Add your own vehicle</a>
                  </li>
                  {/* Driver Service */}
                  <li className="d-none">
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="d-none" onClick={() => router.push("driver_service")}>
                    <a className="dropdown-item">Safe Drivers</a>
                  </li>
                </ul>
              </li>
              <li
                className="nav-item links"
                onClick={() => {
                  router.push("yachts");
                }}
              >
                <a className="navbar_links links_hover nav-link">
                  <Image
                    src={yachtIcon}
                    alt="yacht-icon"
                    height={20}
                    width={20}
                    className="nav-icons"
                  />
                  Yacht Rental
                </a>
              </li>

              <li
                className="nav-item links"
                onClick={() => {
                  router.push("tour-package");
                }}
              >
                <a className="navbar_links links_hover nav-link">
                  {/* <Image src={camelIcon} alt="camel-icon" height={20} width={20} className='nav-icons' /> */}
                  <Image
                    src={carIcon}
                    alt="camel-icon"
                    height={20}
                    width={20}
                    className="nav-icons"
                  />
                  Tour Packages
                </a>
              </li>
              {/* <li
                className="nav-item links"
                onClick={() => {
                  router.push("quad-biking");
                }}
              >
                <a className="navbar_links links_hover nav-link">
                  <Image
                    src={bikeIcon}
                    alt="bike-icon"
                    height={17}
                    width={17}
                    className="nav-icons"
                  />
                  Quad Bike
                </a>
              </li> */}
              
              <li
                onMouseOver={() => {
                  document.querySelector(".dropdown-menu-deals").classList.add("show");
                }}
                onMouseLeave={() => {
                  document.querySelector(".dropdown-menu-deals").classList.remove("show");
                }}
                className="nav-item links dropdown"
              >
                <a
                  className="navbar_links links_hover nav-link dropdown-toggle hot-deals"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{color:'red'}}
                >
                  <Image
                    src={carIcon}
                    alt="car-icon"
                    height={20}
                    width={20}
                    className="nav-icons"
                  />
                  Hot Deals
                </a>
                <ul className="dropdown-menu dropdown-menu-deals">
                  <li onClick={() => router.push("flash-sale")}>
                    <a className="dropdown-item">Flash Sale</a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li onClick={() => router.push("zero-deposit")}>
                    <a className="dropdown-item">Zero Deposit</a>
                  </li>
                  {/* Driver Service */}
                  <li className="d-none">
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="d-none" onClick={() => router.push("driver_service")}>
                    <a className="dropdown-item">Safe Drivers</a>
                  </li>
                </ul>
              </li>
              <li
                className="nav-item links dropdown"
                onMouseOver={() => {
                  document
                    .querySelector("#quick-links-dropdown")
                    .classList.add("show");
                }}
                onMouseLeave={() => {
                  document
                    .querySelector("#quick-links-dropdown")
                    .classList.remove("show");
                }}
              >
                <a
                  className="navbar_links links_hover nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <Image
                    src={quickLinksIcon}
                    alt="driver-icon"
                    height={17}
                    width={17}
                    className="nav-icons"
                  />
                  Quick Links
                </a>

                <ul
                  id="quick-links-dropdown"
                  className="dropdown-menu quick-links-dropdown"
                >
                  {quickLinksData.length > 0
                    ? quickLinksData.map((item, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() => handleClickquick(item)}
                          >
                            <a className="dropdown-item">{item}</a>
                          </li>
                        );
                      })
                    : ""}
                  {carTypeData.length > 0
                    ? carTypeData.slice(0, 6).map((item, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() => {
                              router.push(
                                {
                                  pathname: "/cars",
                                  search: `?${createSearchParams({
                                    type: item.carType.toLowerCase(),
                                  })}`,
                                },
                                {
                                  state: {
                                    filterBy: "carType",
                                    value: item.id,
                                  },
                                }
                              );
                            }}
                          >
                            <a className="dropdown-item">
                              {item.carType.toUpperCase()} CARS RENTALS IN DUBAI
                            </a>
                          </li>
                        );
                      })
                    : ""}
                  <li
                    onClick={() => {
                      router.push("/quad_biking");
                    }}
                  >
                    <a className="dropdown-item">BUGGY RENTALS IN DUBAI</a>
                  </li>
                  <li
                    onClick={() => {
                      router.push("/desert_safari");
                    }}
                  >
                    <a className="dropdown-item">TOUR PACKAGES IN DUBAI</a>
                  </li>
                  {brandsData.length > 0
                    ? brandsData.slice(0, 6).map((item, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() => {
                              router.push({
                                pathname: "/cars",
                                search: `?${createSearchParams({
                                  brand: item.brandName,
                                })}`,
                              });
                            }}
                          >
                            <a className="dropdown-item">
                              {item.brandName.toUpperCase()} CAR RENTALS IN
                              DUBAI
                            </a>
                          </li>
                        );
                      })
                    : ""}
                  <li onClick={handleNavigationyachts}>
                    <a className="dropdown-item">YACHTS RENTALS IN DUBAI</a>
                  </li>
                  <li
                    onClick={() => {
                      router.push("/driver-service");
                    }}
                  >
                    <a className="dropdown-item">DRIVER SERVICES IN DUBAI</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="mobile-navbar-header-container">
            <Image
              src={hamBurger}
              onClick={() => {
                document
                  .querySelector(".mobile-navbar-modal")
                  .classList.add("active-nav-modal");
              }}
              alt="hamburger"
              className="mt-0 mb-0 ms-3 me-2"
              height={25}
              width={25}
            />
            <Image
              onClick={() => {
                router.push("/");
              }}
              src={logo}
              alt="duby-drive-logo"
              height={33}
              width={33}
            />
            <h5
              onClick={() => {
                router.push("/");
              }}
              className="mb-0 text-white ms-2"
            >
              Duby Drive
            </h5>
          </div>
        </div>

        <div
          className="mega-menu-container"
          style={{ display: megaMenu == true ? "flex" : "none" }}
        >
          <div
            className="mega-menu col-md-10"
            onMouseOver={() => {
              setMegaMenu(true);
            }}
            onMouseLeave={() => {
              setMegaMenu(false);
            }}
          >
            {brandsData.length > 0
              ? brandsData.map((item, index) => {
                  return (
                    // <div key={index} className="col-md-2 menu-cards" onClick={() => { router.push({ pathname: '/cars_filter', search: `?${createSearchParams({ brand: item.brandName.toLowerCase() })}` }, { state: { filterBy: 'brand', value: item.id } }) }}>
                    <div
                      key={index}
                      className="col-md-2 brand-list d-flex"
                      onClick={() => handleClick(item)}
                    >
                      <div className="">
                        <Image
                          width={40}
                          height={40}
                          src={`${item.brandLogo}`}
                          style={{ objectFit: "contain" }}
                          alt={item.brandLogo}
                        />
                      </div>
                      <div
                        className="d-flex ms-1 align-items-baseline my-auto"
                        style={{ fontSize: "14px" }}
                      >
                        <h5 className="mb-0" style={{ fontSize: "14px" }}>
                          {item.brandName}{" "}
                        </h5>
                        &nbsp; (
                        <h6 className="mb-0" style={{ fontSize: "13px" }}>
                          {item.totalCars}
                        </h6>
                        )
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>

        <div
          className="mega-menu-container"
          style={{
            display: rentACarMenu == true ? "flex" : "none",
            position: "absolute",
            left: "18%",
          }}
        >
          <div
            className="mega-menu ms-5 p-0"
            onMouseOver={() => {
              setRentACarMenu(true);
            }}
            onMouseLeave={() => {
              setRentACarMenu(false);
            }}
          >
            <div className="d-flex">
              <Image
                src={menuItemBg}
                className="menu-item-bg-Image"
                alt="menu-item-bg"
              />
              <ul className="rent-a-car-menu-list-container">
                {citiesData.length > 0
                  ? citiesData.slice(0, 8).map((item, index) => {
                      return (
                        // <li key={index} onClick={() => { router.push({ pathname: '/cars_filter', search: `?${createSearchParams({ city: item.cityName.toLowerCase() })}` }, { state: { filterBy: 'city', value: item.id } }) }}>
                        <li key={index}>
                          <Link href={`/cars?city=${item.name}`}>
                            {item.name}
                          </Link>
                        </li>
                      );
                    })
                  : ""}
              </ul>
            </div>
          </div>
        </div>

        <div className="mobile-navbar-modal background-transparent">
          <div className="mobile-navbar-container">
            <div className="mobile-nav-header">
              <Image
                onClick={() => {
                  router.push("/");
                }}
                src={logo}
                alt="logo"
                className="mobile-nav-header-logo"
              />
              <h4
                onClick={() => {
                  router.push("/");
                }}
              >
                DubyDrive
              </h4>
              <span
                className="mobile-nav-close-icon"
                onClick={() => {
                  document
                    .querySelector(".mobile-navbar-modal")
                    .classList.remove("active-nav-modal");
                }}
              >
                &times;
              </span>
            </div>
            <div className="mobile-nav-body">
              <button
                className="border-0 p-2 rounded primary_background_color text-white d-flex align-items-center ms-2 mb-2"
                onClick={() => {
                  document
                    .querySelector(".mobile-navbar-modal")
                    .classList.remove("active-nav-modal");
                  router.push("/add-your-vehicle");
                }}
              >
                <Image
                  src={carIcon}
                  alt="car-icon"
                  height={18}
                  width={18}
                  className="me-1"
                />
                <span>Register your vehicle</span>
              </button>

              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed p-4"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseZero"
                      aria-expanded="false"
                      aria-controls="flush-collapseZero"
                    >
                      <Image
                        src={navLocationsIcon}
                        alt="cities-icon"
                        height={20}
                        width={20}
                        className="nav-icons"
                      />
                      Locations
                    </button>
                  </h2>
                  <div
                    id="flush-collapseZero"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div>
                        {citiesData.length > 0
                          ? citiesData.slice(0, 8).map((item, index) => {
                              return (
                                <p
                                  className="mobile-nav-links"
                                  key={index}
                                  onClick={() => {
                                    document
                                      .querySelector(".mobile-navbar-modal")
                                      .classList.remove("active-nav-modal");
                                  }}
                                >
                                  <Link href={`/cars?city=${item.name}`}>
                                    {item.name}
                                  </Link>
                                </p>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed p-4"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      <Image
                        src={navBrandIcon}
                        alt="brand-icon"
                        height={23}
                        width={23}
                        className="nav-icons"
                      />
                      Car Brands
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div className="mt-3">
                        {brandsData.length > 0
                          ? brandsData.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="col-md-2 menu-cards"
                                  onClick={() => {
                                    handleClick(item);
                                    document
                                      .querySelector(".mobile-navbar-modal")
                                      .classList.remove("active-nav-modal");
                                  }}
                                >
                                  <div className="me-15">
                                    <Image
                                      src={`${item.brandLogo}`}
                                      width={100}
                                      height={100}
                                      style={{ objectFit: "contain" }}
                                      className="mega-menu-brand-card-Image me-2"
                                      alt={item.brandLogo}
                                    />
                                  </div>
                                  <div className="d-flex flex-column justify-content-start ms-1">
                                    <h5 className="mb-0">{item.brandName}</h5>
                                    <h6 className="mb-0">
                                      {lengthData.map((itm, ind) => {
                                        return (
                                          <span key={ind}>
                                            {item.id == itm.carBrandId
                                              ? itm.total_length + " Cars"
                                              : ""}
                                          </span>
                                        );
                                      })}
                                    </h6>
                                  </div>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed p-4"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      <Image
                        src={navCarIcon}
                        alt="car-icon"
                        height={20}
                        width={20}
                        className="nav-icons"
                      />
                      Rental Cars
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div>
                        <p
                          className="mobile-nav-links"
                          onClick={() => {
                            document
                              .querySelector(".mobile-navbar-modal")
                              .classList.remove("active-nav-modal");
                            router.push("cars");
                          }}
                        >
                          <a className="dropdown-item">Rental Cars</a>
                        </p>
                        <p
                          className="mobile-nav-links"
                          onClick={() => {
                            document
                              .querySelector(".mobile-navbar-modal")
                              .classList.remove("active-nav-modal");
                            router.push("/add-your-vehicle");
                          }}
                        >
                          <a className="dropdown-item">Add your own vehicle</a>
                        </p>
                        <p
                          className="mobile-nav-links"
                          onClick={() => {
                            document
                              .querySelector(".mobile-navbar-modal")
                              .classList.remove("active-nav-modal");
                            // router.push("driver_service");
                          }}
                        >
                          <a className="dropdown-item">Safe Drivers</a>
                        </p>
                        {/* {driverServiceData.length > 0 ?
                                                    <>
                                                        {
                                                            driverServiceData.map((item, index) => {
                                                                return (
                                                                    <p className='mobile-nav-links' key={index} onClick={() => { document.querySelector('.mobile-navbar-modal').classList.remove('active-nav-modal'); router.push({ pathname: 'driver_service', search: `?${createSearchParams({ q: `${item.servicePeriod.toLowerCase()} ${item.serviceType.toLowerCase()}` })}` }, { state: item.id }) }}>
                                                                        <a className="dropdown-item">
                                                                            {item.servicePeriod} {item.serviceType}
                                                                        </a>
                                                                    </p>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                    :
                                                    ''
                                                } */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed p-4"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      <Image
                        src={navYachtIcon}
                        alt="yacht-icon"
                        height={20}
                        width={20}
                        className="nav-icons"
                      />
                      Yacht Rentals
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div>
                        <p
                          className="mobile-nav-links"
                          onClick={() => {
                            document
                              .querySelector(".mobile-navbar-modal")
                              .classList.remove("active-nav-modal");
                            {
                              handleNavigationyachts;
                            }
                          }}
                        >
                          <a className="dropdown-item">Rental a Yacht</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed p-4"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      <Image
                        src={navCarIcon}
                        alt="camel-icon"
                        height={20}
                        width={20}
                        className="nav-icons"
                      />
                      Tour Packages
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div>
                        <p
                          className="mobile-nav-links"
                          onClick={() => {
                            document
                              .querySelector(".mobile-navbar-modal")
                              .classList.remove("active-nav-modal");
                            router.push("/tour-package");
                          }}
                        >
                          <a className="dropdown-item">Tour Packages</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed p-4"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFive"
                      aria-expanded="false"
                      aria-controls="flush-collapseFive"
                    >
                      <Image
                        src={navBikingIcon}
                        alt="bike-icon"
                        height={17}
                        width={17}
                        className="nav-icons"
                      />
                      Quad Biking
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFive"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div>
                        <p
                          className="mobile-nav-links"
                          onClick={() => {
                            document
                              .querySelector(".mobile-navbar-modal")
                              .classList.remove("active-nav-modal");
                            router.push("/quad_biking");
                          }}
                        >
                          <a className="dropdown-item">Quad Biking</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed p-4"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSix"
                      aria-expanded="false"
                      aria-controls="flush-collapseSix"
                    >
                      <Image
                        src={navLinksIcon}
                        alt="driver-icon"
                        height={17}
                        width={17}
                        className="nav-icons"
                      />
                      Quick Links
                    </button>
                  </h2>
                  <div
                    id="flush-collapseSix"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div>
                        {quickLinksData.length > 0
                          ? quickLinksData.map((item, index) => {
                              return (
                                <p
                                  className="mobile-nav-links"
                                  key={index}
                                  onClick={() => {
                                    document
                                      .querySelector(".mobile-navbar-modal")
                                      .classList.remove("active-nav-modal");
                                    router.push({
                                      pathname: "/cars",
                                      search: `?${createSearchParams({
                                        quick_link: item.toLowerCase(),
                                      })}`,
                                    });
                                  }}
                                >
                                  <a className="dropdown-item">{item}</a>
                                </p>
                              );
                            })
                          : ""}
                        {carTypeData.length > 0
                          ? carTypeData.map((item, index) => {
                              return (
                                <p
                                  className="mobile-nav-links"
                                  key={index}
                                  onClick={() => {
                                    document
                                      .querySelector(".mobile-navbar-modal")
                                      .classList.remove("active-nav-modal");
                                    router.push(
                                      {
                                        pathname: "/cars",
                                        search: `?${createSearchParams({
                                          type: item.carType.toLowerCase(),
                                        })}`,
                                      },
                                      {
                                        state: {
                                          filterBy: "carType",
                                          value: item.id,
                                        },
                                      }
                                    );
                                  }}
                                >
                                  <a className="dropdown-item">
                                    {item.carType.toUpperCase()} CARS RENTALS IN
                                    DUBAI
                                  </a>
                                </p>
                              );
                            })
                          : ""}
                        <p
                          className="mobile-nav-links"
                          onClick={() => {
                            document
                              .querySelector(".mobile-navbar-modal")
                              .classList.remove("active-nav-modal");
                            router.push("/quad_biking");
                          }}
                        >
                          <a className="dropdown-item">
                            BUGGY RENTALS IN DUBAI
                          </a>
                        </p>
                        {brandsData.length > 0
                          ? brandsData.map((item, index) => {
                              return (
                                <p
                                  className="mobile-nav-links"
                                  key={index}
                                  onClick={() => {
                                    document
                                      .querySelector(".mobile-navbar-modal")
                                      .classList.remove("active-nav-modal");
                                    router.push(
                                      {
                                        pathname: "/cars",
                                        search: `?${createSearchParams({
                                          brand: item.brandName,
                                        })}`,
                                      },
                                      {
                                        state: {
                                          filterBy: "brand",
                                          value: item.id,
                                        },
                                      }
                                    );
                                  }}
                                >
                                  <a className="dropdown-item">
                                    {item.brandName.toUpperCase()} CAR RENTALS
                                    IN DUBAI
                                  </a>
                                </p>
                              );
                            })
                          : ""}
                        <p
                          className="mobile-nav-links"
                          onClick={() => {
                            document
                              .querySelector(".mobile-navbar-modal")
                              .classList.remove("active-nav-modal");
                            {
                              handleNavigationyachts;
                            }
                          }}
                        >
                          <a className="dropdown-item">
                            YACHTS RENTALS IN DUBAI
                          </a>
                        </p>
                        <p
                          className="mobile-nav-links"
                          onClick={() => {
                            document
                              .querySelector(".mobile-navbar-modal")
                              .classList.remove("active-nav-modal");
                            router.push("/driver_service");
                          }}
                        >
                          <a className="dropdown-item">
                            DRIVER SERVICES IN DUBAI
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column justify-content-center align-items-center mt-2 mb-5">
                <div className="mb-2 d-flex">
                  <a
                    onClick={() => {
                      document
                        .querySelector(".mobile-navbar-modal")
                        .classList.remove("active-nav-modal");
                    }}
                    className="d-flex header-links links_hover"
                    href="#"
                  >
                    <Image
                      className="header-links-icons"
                      src={earthIcon}
                      height={20}
                      width={20}
                      alt="earth-icon"
                    />
                    <span className="h5 mb-0">UAE</span>
                  </a>
                  <a
                    className="d-flex header-links links_hover"
                    onClick={() => {
                      document
                        .querySelector(".mobile-navbar-modal")
                        .classList.remove("active-nav-modal");
                      router.push("/rental-companies");
                    }}
                  >
                    <Image
                      className="header-links-icons"
                      src={companyIcon}
                      height={20}
                      width={20}
                      alt="company-icon"
                    />
                    <span className="h5 mb-0">Rental Companies</span>
                  </a>
                </div>
                <h5 className="text-center">Follow Us:</h5>
                <div className="d-flex align-items-center justify-content-center me-3 ms-3">
                  <Image
                    src={fbIcon}
                    alt="social-media-icon"
                    className="m-2 background-whitesmoke p-2 rounded"
                    height={45}
                    width={45}
                  />
                  <Image
                    src={instaIcon}
                    alt="social-media-icon"
                    className="m-2 background-whitesmoke p-2 rounded"
                    height={45}
                    width={45}
                  />
                  <Image
                    src={tiktokIcon}
                    alt="social-media-icon"
                    className="m-2 background-whitesmoke p-2 rounded"
                    height={45}
                    width={45}
                  />
                  <Image
                    src={youtubeIcon}
                    alt="social-media-icon"
                    className="m-2 background-whitesmoke p-2 rounded"
                    height={45}
                    width={45}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              document
                .querySelector(".mobile-navbar-modal")
                .classList.remove("active-nav-modal");
            }}
            className="nav-close-vertical-bar"
          ></div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
