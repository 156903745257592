'use client';
import React from 'react';
import { useRouter } from 'next/navigation';
import { toast } from 'react-toastify';

import Image from 'next/image';

import logo from "../../assets/home/dubydrive_logo.webp";

import playStoreBtn from "../../assets/download-btn/google-btn.png";
import appStoreBtn from "../../assets/download-btn/apple-btn.png";

import phoneIcon from "../../assets/icons/phone-call.png";
import emailIcon from "../../assets/icons/email.png";
import pinIcon from "../../assets/icons/pin.png";

import masterCard from '../../assets/cards/master-card.png';
import visaCard from '../../assets/cards/visa-card.png';
import expressCard from '../../assets/cards/express-card.png';

import fbIcon from "../../assets/icons/facebook.png";
import instaIcon from "../../assets/icons/instagram.png";
import xIcon from "../../assets/icons/x.png";
import youtubeIcon from "../../assets/icons/youtube.png";
import tiktokIcon from "../../assets/icons/tiktok.png";

const Footer = () => {

    const router = useRouter();

    return (
        <>
            <div className="footer-container p-3" style={{background:'#262626'}}>
                <div className="footer-row-one">
                    <div className='col-md-3 mb-3'>
                        <div className="footer-company-details-container">
                            <div className='footer-company-logo-container'>
                                <Image src={logo} alt="logo" />
                                <h3 className='text-white'>DubyDrive</h3>
                            </div>
                            <div className='footer-company-details'>
                                <p className='text-white'>
                                    Find and book our budget-friendly and affordable car rentals, Chauffeur service, Tour Packages and Luxury Yacht Rentals Deals & Services. We are providing our services in all major cities worldwide.
                                </p>
                            </div>
                            {/* <div className='footer-app-download-btn-container'>
                                <Image src={playStoreBtn} alt="play-store-btn" onClick={() => { toast.warn('Currently we are working on our app') }} />
                                <Image src={appStoreBtn} alt="app-store-btn" onClick={() => { toast.warn('Currently we are working on our app') }} />
                            </div> */}
                        </div>
                    </div>
                    <div className='col-md-4 mb-3'>
                        <div className="footer-links-container text-white">
                            <div className="footer-links-container-one">
                                <a href="/frequently-asked-questions" className="page-links footer-page-links">Dubai Car Rental FAQs</a>
                                <a href='/brands' target="_blank" className="page-links footer-page-links">Rent by Brands</a>
                                <a href='yachts' target="_blank" className="page-links footer-page-links">Yacht Rental</a>
                                <a href='/tour-package' target="_blank" className="page-links footer-page-links">Tour Package</a>
                                <a href='cars' target="_blank" className="page-links footer-page-links">Car with Driver</a>
                                <a href='/rental-companies' target="_blank" className="page-links footer-page-links">Car Rental Directory</a>
                                <a href='https://blog.dubydrive.com' target="_blank" className="page-links footer-page-links">Blogs</a>
                            </div>
                            <div className="footer-links-container-two">
                                <a href='/quad-biking' target="_blank" className="page-links footer-page-links">Dune Bashing</a>
                                <a href='/about' target="_blank" className="page-links footer-page-links">About US</a>
                                {/* <p onClick={() => { router.push('/') }} className="page-links footer-page-links">DubyDrive App</p> */}
                                <a href='/terms-and-conditions' target="_blank" className="page-links footer-page-links">Terms and Conditions</a>
                                <a href='/terms-of-use' target="_blank" className="page-links footer-page-links">Terms of use</a>
                                <a href='/privacy-policy' target="_blank" className="page-links footer-page-links">Privacy Policy</a>
                                <a href='/contact' target="_blank" className="page-links footer-page-links">Contact US</a>
                                <a href='/add-your-vehicle' target="_blank" className="page-links footer-page-links">Add Your Own Vehicle</a>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-2 mb-3'>
                        <div className="footer-contact-details-container">
                            <div className="footer-contact-details">
                                <h6 className='text-gray'>For Inquiries & Support</h6>
                                <div className='d-flex align-items-center'>
                                    <Image src={phoneIcon} alt="phone-icon" className='footer-links-icons' />
                                    <span className='text-white page-links'>+971 50 571 4766</span>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <Image src={emailIcon} alt="email-icon" className='footer-links-icons' />
                                    <span className='text-white page-links'>sales@dubydrive.com</span>
                                </div>
                            </div>
                            <div className="footer-contact-details">
                                <h6 className='text-gray'>For Car with Driver</h6>
                                <div className='d-flex align-items-center'>
                                    <Image src={phoneIcon} alt="phone-icon" className='footer-links-icons' />
                                    <span className='text-white page-links'>+14168273334</span>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <Image src={emailIcon} alt="email-icon" className='footer-links-icons' />
                                    <span className='text-white page-links'>admin@dubydrive.com</span>
                                </div>
                            </div>
                            <div className="footer-contact-details">
                                <h6 className='text-gray'>Advertise with Us</h6>
                                <div className='d-flex align-items-center'>
                                    <Image src={phoneIcon} alt="phone-icon" className='footer-links-icons' />
                                    <span className='text-white page-links'>+14168274441</span>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <Image src={emailIcon} alt="email-icon" className='footer-links-icons' />
                                    <span className='text-white page-links'>admin@dubydrive.com</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3 mb-3'>
                        <div className="footer-location-details-container">
                            <div className="footer-contact-details">
                                <h6 className='text-gray'>Address</h6>
                                <div className='d-flex align-items-center mb-2'>
                                    <Image src={pinIcon} alt="pin-icon" className='footer-links-icons mb-4' />
                                    <span className='text-white page-links'>60 Panatella street, unit 4412 NW, Calgary AB Canada T3K 0M4</span>
                                </div>
                            </div>
                            <div className="footer-map-container">
                                <iframe
                                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10007.729091344227!2d-114.10929303590956!3d51.16503800426229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5371665603b2f365%3A0x4769db39a7454921!2s60%20Panatella%20St%20NW%2C%20Calgary%2C%20AB%20T3K%200M4%2C%20Canada!5e0!3m2!1sen!2s!4v1723050877235!5m2!1sen!2s'
                                    height={200}
                                    width={'100%'}
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-row-one mobile-footer-one">
                    <div className='col-md-2 mb-3 footer-contact-details-container-parent'>
                        <div className="footer-contact-details-container">
                            <div className="footer-contact-details">
                                <h6 className='text-gray'>For Inquiries & Support</h6>
                                <div className='d-flex align-items-center'>
                                    <Image src={phoneIcon} alt="phone-icon" className='footer-links-icons' />
                                    <span className='text-white page-links'>+971 50 571 4766</span>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <Image src={emailIcon} alt="email-icon" className='footer-links-icons' />
                                    <span className='text-white page-links'>sales@dubydrive.com</span>
                                </div>
                            </div>
                            <div className="footer-contact-details">
                                <h6 className='text-gray'>Complaints and concerns</h6>
                                <div className='d-flex align-items-center'>
                                    <Image src={phoneIcon} alt="phone-icon" className='footer-links-icons' />
                                    <span className='text-white page-links'>+14168273334</span>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <Image src={emailIcon} alt="email-icon" className='footer-links-icons' />
                                    <span className='text-white page-links'>admin@dubydrive.com</span>
                                </div>
                            </div>
                            <div className="footer-contact-details">
                                <h6 className='text-gray'>Advertise with Us</h6>
                                <div className='d-flex align-items-center'>
                                    <Image src={phoneIcon} alt="phone-icon" className='footer-links-icons' />
                                    <span className='text-white page-links'>+14168274441</span>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <Image src={emailIcon} alt="email-icon" className='footer-links-icons' />
                                    <span className='text-white page-links'>admin@dubydrive.com</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3 mb-3 footer-location-details-container-parent'>
                        <div className="footer-location-details-container">
                            <div className="footer-contact-details">
                                <h6 className='text-gray'>Address</h6>
                                <div className='d-flex align-items-center mb-2'>
                                    <Image src={pinIcon} alt="pin-icon" className='footer-links-icons mb-4' />
                                    <span className='text-white page-links'>60 Panatella street, unit 4412 NW, Calgary AB Canada T3K 0M4</span>
                                </div>
                            </div>
                            <div className="footer-map-container">
                                <iframe
                                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10007.729091344227!2d-114.10929303590956!3d51.16503800426229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5371665603b2f365%3A0x4769db39a7454921!2s60%20Panatella%20St%20NW%2C%20Calgary%2C%20AB%20T3K%200M4%2C%20Canada!5e0!3m2!1sen!2s!4v1723050877235!5m2!1sen!2s'
                                    height={200}
                                    width={'100%'}
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 mb-3 footer-links-container-parent'>
                        <div className="footer-links-container text-white">
                            <div className="footer-links-container-one">
                                <a href="/frequently-asked-questions" className="page-links footer-page-links">Dubai Car Rental FAQs</a>
                                <a href='/brands' target="_blank" className="page-links footer-page-links">Rent by Brands</a>
                                <a href='yachts' target="_blank" className="page-links footer-page-links">Yacht Rental</a>
                                <a href='/tour-package' target="_blank" className="page-links footer-page-links">Tour Package</a>
                                <a href='cars' target="_blank" className="page-links footer-page-links">Car with Driver</a>
                                <a href='/rental-companies' target="_blank" className="page-links footer-page-links">Car Rental Directory</a>
                                <a href='https://blog.dubydrive.com' target="_blank" className="page-links footer-page-links">Blogs</a>
                            </div>
                            <div className="footer-links-container-two">
                                <a href='/quad-biking' target="_blank" className="page-links footer-page-links">Dune Bashing</a>
                                <a href='/about' target="_blank" className="page-links footer-page-links">About US</a>
                                {/* <p onClick={() => { router.push('/') }} className="page-links footer-page-links">DubyDrive App</p> */}
                                <a href='/terms-and-conditions' target="_blank" className="page-links footer-page-links">Terms and Conditions</a>
                                <a href='/terms-of-use' target="_blank" className="page-links footer-page-links">Terms of use</a>
                                <a href='/privacy-policy' target="_blank" className="page-links footer-page-links">Privacy Policy</a>
                                <a href='/contact' target="_blank" className="page-links footer-page-links">Contact US</a>
                                <a href='/add-your-vehicle' target="_blank" className="page-links footer-page-links">Add Your Own Vehicle</a>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3 mb-3 footer-company-details-container-parent'>
                        <div className="footer-company-details-container">
                            <div className='footer-company-logo-container'>
                                <Image src={logo} alt="logo" />
                                <h3 className='text-white'>DubyDrive</h3>
                            </div>
                            <div className='footer-company-details'>
                                <p className='text-white'>
                                    Find and book our budget-friendly and affordable car rentals, Chauffeur service, Tour Packages and Luxury Yacht Rentals Deals & Services. We are  Located in Dubai, and Providing our services in all major cities in United Arab Emirates (UAE).
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer-row-two'>
                    <div className="footer-payment-cards-container">

                        <div className="footer-payment-cards">
                            <Image src={visaCard} alt="visa-card" className='footer-payment-card-Image' />
                        </div>
                        <div className="footer-payment-cards">
                            <Image src={expressCard} alt="express-card" className='footer-payment-card-Image' />
                        </div>
                        <div className="footer-payment-cards">
                            <Image src={masterCard} alt="master-card" className='footer-payment-card-Image' />
                        </div>

                    </div>
                    <p className="footer-bottom-text text-white text-center">
                        Dubydrive.com – a part of <span onClick={() => { window.open(`https://intellectualclouds.com/`, "_blank") }} className='page-links'>Intellectual Clouds LLC</span>
                    </p>
                    <div className='footer-social-media-icons-container'>
                        <a href="https://www.facebook.com/dubydrive" target="_blank" rel="noopener noreferrer">
                            <Image src={fbIcon} alt="social-media-icon" />
                        </a>
                        <a href="https://www.instagram.com/dubydriveportal" target="_blank" rel="noopener noreferrer">
                            <Image src={instaIcon} alt="social-media-icon" />
                        </a>
                        <a href="https://www.tiktok.com/@dubydrive" target="_blank" rel="noopener noreferrer">
                            <Image src={tiktokIcon} alt="social-media-icon" />
                        </a>
                        <a href="https://www.youtube.com/@DubyDrivePortal" target="_blank" rel="noopener noreferrer">
                            <Image src={youtubeIcon} alt="social-media-icon" />
                        </a>
                        {/* <Image src={xIcon} alt="social-media-icon" /> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;