'use client'; // Add this directive to mark the component as a Client Component

import { useRouter } from 'next/navigation';
import Image from 'next/image';

// Static assets
import logo from '../../assets/home/dubydrive_logo.webp';
import earthIcon from '../../assets/icons/planet-earth.png';
import emailIcon from '../../assets/icons/email.png';
import phoneIcon from '../../assets/icons/phone-call.png';
import companyIcon from '../../assets/icons/company.png';
import sportsCarIcon from '../../assets/icons/sports-car.png';

import fbIcon from '../../assets/icons/facebook.png';
import instaIcon from '../../assets/icons/instagram.png';
import tiktokIcon from '../../assets/icons/tiktok.png';
import youtubeIcon from '../../assets/icons/youtube.png';
import '../../../src/styles/header.css'

const Header = () => {
    const router = useRouter();

    const handleNavigation = () => {
        router.push('/add-your-vehicle');
    };

    return (
        <nav className="navbar bg-body-tertiary p-0">
            <div className="container-fluid header-container">
                <a className="navbar-brand header-logo-btn" onClick={() => router.push('/')}>
                    <Image
                        src={logo}
                        alt="logo"
                        width={35}
                        height={35}
                        className="d-inline-block align-text-top header-logo"
                    />
                    Duby Drive
                </a>

                <div className='header-links-container'>
                    <a className='header-links links_hover' href="/">
                        <Image className='header-links-icons' src={earthIcon} height={17} width={17} alt="earth-icon" />
                        UAE
                    </a>
                    <a className='header-links links_hover' href="#">
                        <Image className='header-links-icons' src={emailIcon} height={17} width={17} alt="email-icon" />
                        sales@dubydrive.com
                    </a>
                    <a className='header-links links_hover' href="#">
                        <Image className='header-links-icons' src={phoneIcon} height={17} width={17} alt="phone-icon" />
                        +971 505714766
                    </a>
                    <a className='header-links links_hover' onClick={() => router.push('/rental-companies')}>
                        <Image className='header-links-icons' src={companyIcon} height={17} width={17} alt="company-icon" />
                        Rental Companies
                    </a>
                    <a className='header-links links_hover background-orange text-white rounded' onClick={handleNavigation}>
                        <Image className='header-links-icons' src={sportsCarIcon} height={17} width={17} alt="sports-car-icon" />
                        Register your vehicle
                    </a>
                    <div className="d-flex align-self-center justify-content-center me-3 ms-3">
                        <a href="https://www.facebook.com/dubydrive" target="_blank" rel="noopener noreferrer">
                            <Image src={fbIcon} alt="social-media-icon" className='small-icon' width={24} height={24} />
                        </a>
                        <a href="https://www.instagram.com/dubydriveportal" target="_blank" rel="noopener noreferrer">
                            <Image src={instaIcon} alt="social-media-icon" className='large-icon' width={24} height={24} />
                        </a>
                        <a href="https://www.tiktok.com/@dubydrive" target="_blank" rel="noopener noreferrer">
                            <Image src={tiktokIcon} alt="social-media-icon" className='large-icon' width={24} height={24} />
                        </a>
                        <a href="https://www.youtube.com/@DubyDrivePortal" target="_blank" rel="noopener noreferrer">
                            <Image src={youtubeIcon} alt="social-media-icon" className='small-icon' width={24} height={24} />
                        </a>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Header;
